html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

p {
    font-size: 16px;
}

h1 {
    color: #ffffff;
    text-align: center;
    padding: 30px 0px
}

h2 {
    color: #ffffff;
}